import { type IUser } from "helpers/formUtilities";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useAuthenticatedFetch from "hooks/useFetchWithMsal";
import { protectedResources } from "authConfig";
import { UserContext } from "context/UserProvider";



export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a AppDataProvider");
  }

  return context;
};

type useUserGetMeProps = {
  enabled?: boolean;
};

export const useUserGetMe = ({ enabled = true }: useUserGetMeProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
  });

  const [data, setData] = useState<IUser | undefined>(undefined);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userme-get");

    const user = data?.d ?? undefined;

    setData(user);

    return user;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};

type useUserSyncADMeProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};

export const useUserSyncADMe = ({
  onSuccess,
  onError,
}: useUserSyncADMeProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.write,
  },
  {
    onSuccess,
    onError,
  });

  const mutate = useCallback(async (user: {
    username: string;
    email: string;
    name?: string;
    // customerName?: string;
    // permissionLevel?: number;
    // permissionGroup?: string;
  }) => {
    const { data } = await execute("PUT", "/api/usersync-put", user);
    return data?.d;
  }, [execute]);

  return { isLoading, error, mutate };
};

export const isSuperUser = (user: IUser | undefined) => {
  return (
    user?.permissionGroupId?.toString() === "0" &&
    user?.permissionLevelId?.toString() === "0"
  );
};

export const isAdminUser = (user: IUser | undefined) => {
  return user?.permissionLevelId?.toString() === "0";
};

type useUserGetManyProps = {
  enabled?: boolean;
};

export const useUserGetMany = ({
  enabled = true,
}: useUserGetManyProps = {}) => {
  const [data, setData] = useState<IUser[]>([]);

  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
    enabled,
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/users-get");

    const users = data?.d ?? [];

    setData(users);

    return users;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};

type useUserGetOneByIdProps = {
  enabled?: boolean;
  id: string;
};

export const useUserGetOneById = ({
  enabled = true,
  id,
}: useUserGetOneByIdProps) => {
  const [data, setData] = useState<IUser | undefined>(undefined);

  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
    enabled,
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/user-get?id=${id}`
    );

    const user = data?.d;

    setData(user);

    return user as Partial<IUser>;
  }, [execute, id]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};

type useUserGetOrgLevelsProps = {
  enabled?: boolean;
};

type orgLevelSchema = {
  id: number;
  name: string;
};

export const useUserGetOrgLevels = ({
  enabled = true,
}: useUserGetOrgLevelsProps = {}) => {
  const [data, setData] = useState<orgLevelSchema[]>([]);

  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
    enabled,
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userorglevels-get");

    const orgLevels = data?.d ?? [];

    setData(orgLevels);

    return orgLevels as orgLevelSchema[];
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};

type useUserGetPermissionGroupsProps = {
  enabled?: boolean;
};

type permissionGroupSchema = {
  id: number;
  name: string;
  farmSelectionAccess: string;
};

export const useUserGetPermissionGroups = ({
  enabled = true,
}: useUserGetPermissionGroupsProps = {}) => {
  const [data, setData] = useState<permissionGroupSchema[]>([]);

  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
    enabled,
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userpermissiongroups-get");

    const permissionGroups = data?.d ?? [];

    setData(permissionGroups);

    return permissionGroups as permissionGroupSchema[];
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};

type useUserGetAssignedFarmsProps = {
  enabled?: boolean;
  id: string;
};

type assignedFarmSchema = {
  farmname: string;
  farmcode: string;
};

export const useUserGetAssignedFarms = ({
  enabled = true,
  id,
}: useUserGetAssignedFarmsProps) => {
  const [data, setData] = useState<assignedFarmSchema[]>([]);

  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
    enabled,
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/userassignedfarms-get?id=${id}`
    );

    const farms = data?.d ?? [];

    setData(farms);

    return farms as assignedFarmSchema[];
  }, [execute, id]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
}

type useUserCreateProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};

export const useUserCreate = ({
  onSuccess,
  onError,
}: useUserCreateProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch(
    {
      scopes: protectedResources.api.scopes.write,
    },
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (user: {
      accountEnabled: boolean;
      displayName: string;
      emailAddress: string;
      username: string;
      forceChangePasswordNextSignIn: boolean; // If set to true it will display "The password has expired."
      dotnetOrganisationLevelId: string;
      dotnetPermissionGroupId: string;
      assignedFarms: string[];
      password: string;
    }) => {
      const { data } = await execute("POST", "/api/user-post", user);
      return data?.d;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useUserUpdateProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};

export const useUserUpdate = ({
  onSuccess,
  onError,
}: useUserUpdateProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch(
    {
      scopes: protectedResources.api.scopes.write,
    },
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (user: {
      id: string; // active directory id
      data: {
        displayName: string;
        emailAddress: string;
        dotnetOrganisationLevelId: string;
        dotnetPermissionGroupId: string;
        assignedFarms?: string[];
        password?: string;
      };
    }) => {
      const { data } = await execute("PUT", "/api/user-put", user);
      return data?.d;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useUserDeleteProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};

export const useUserDelete = ({
  onSuccess,
  onError,
}: useUserDeleteProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch(
    {
      scopes: protectedResources.api.scopes.write,
    },
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (id: string) => {
      const { data } = await execute("DELETE", "/api/user-delete", { id });
      return data?.d;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};
