import UserForm from "components/config/user/form";
import { Alert } from "components/core";
import { isAdminUser, isSuperUser, useUser } from "hooks/useUser";

const UserCreatePage = () => {
  const { user } = useUser();

  if (!isAdminUser(user) && !isSuperUser(user)) {
    return (
      <Alert theme="danger">
        You do not have permission to view this page.
      </Alert>
    );
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-full">
          <div className="flex-grow text-lg font-medium uppercase text-gray-600">
            Create user
          </div>
        </div>
        <div className="col-span-full">
          <UserForm />
        </div>
      </div>
    </>
  );
};

export default UserCreatePage;
